import { Card, CardContent, CardMedia, Typography } from "@material-ui/core";

const BoothCard = ({ boothData }) => {
  console.log("BOOTH", boothData);
  return (
    <Card>
      <CardContent>
        <Typography variant="h5">{boothData.name}</Typography>
        <Typography variant="p">{boothData.description}</Typography>
      </CardContent>

      <CardMedia image={boothData.image_url} style={{ height: 250 }} />
    </Card>
  );
};

export default BoothCard;
