import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Fragment, useEffect, useState, useRef, useContext } from "react";
import Paragraph from "./Paragraph";
import TemplateCard from "./TemplateCard";
import NewTemplateForm from "./NewTemplateForm";
import VerticalSpacer from "./VerticalSpacer";
import AddIcon from "@material-ui/icons/Add";

import {
  getPhotoboothPage,
  getBoothTypes,
  updatePhotoboothPage,
  deleteBooth,
  createBoothTypes,
  // getTemplates,
  // updateTemplatePage,
  // createTemplate,
  // deleteTemplate,
  // updateTemplate,
} from "../api";

import { SiteContext, PhotoboothPageContext } from "../Context/SiteContext";
import BoothForm from "./NewBoothForm";
import BoothCard from "./BoothCard";

const EditPhotoboothPage = () => {
  const [showNewBoothForm, setShowNewBoothForm] = useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [currentEditForm, setCurrentEditForm] = useState(null);
  const [photoboothPage, photoboothTypes] = useContext(PhotoboothPageContext);
  const [loadStateData] = useContext(SiteContext);

  useEffect(() => {
    getPhotoboothPage()
      .then((payload) => {
        loadStateData("PHOTOBOOTH_PAGE", payload);
        return payload;
      })
      .then((payload) => {
        setTitle(payload.title);
        setBody(payload.body);
      })
      .catch((err) => alert("unable to load photobooth page data"));
  }, []);

  useEffect(() => {
    getBoothTypes()
      .then((payload) => {
        loadStateData("PHOTOBOOTH_TYPES", payload);
      })
      .catch((err) => alert("unable to load photobooth types data"));
  }, []);

  const handlePhotoboothPageUpdate = () => {
    const updatedPhotoboothPageData = {
      title,
      body,
    };
    console.log("UPDATE PHOTOBOOTH PAGE", updatedPhotoboothPageData);
    updatePhotoboothPage(updatedPhotoboothPageData)
      .then((payload) => {
        loadStateData("PHOTOBOOTH_PAGE", payload);
      })
      .then(() => loadStateData("SUCCESS", "Updated Photobooth Page!"))
      .catch((err) => loadStateData("ERROR", "Error updating Photobooth Page"));
  };

  //   const handleCreateBooth = (boothData) => {
  //     createBoothTypes(boothData)
  //       .then((payload) => loadStateData("CREATE_PHOTOBOOTH_TYPE", payload))
  //       .then(() => setShowNewBoothForm(false))
  //       .then(() => loadStateData("SUCCESS", "Created new booth!"))
  //       .catch((err) => loadStateData("ERROR", "Error creating new booth"));
  //   };

  // const handleUpdateTemplate = (template) => {
  //   // console.log("TEMPLATE", template);
  //   updateTemplate(template, currentEditForm)
  //     .then((payload) => loadStateData("UPDATE_TEMPLATE", payload))
  //     .then(() => setCurrentEditForm(null))
  //     .then(() => loadStateData("SUCCESS", "Updated template!"))
  //     .catch((err) => loadStateData("ERROR", "Error updating template"));
  // };

  const handleDeleteBooth = (id) => {
    deleteBooth(id)
      .then((payload) => loadStateData("DELETE_PHOTOBOOTH_TYPE", payload))
      .then(() => loadStateData("SUCCESS", "Deleted Booth!"))
      .catch((err) => loadStateData("ERROR", "Error deleting booth"));
  };

  // const handleEditClick = (id) => setCurrentEditForm(id);

  const renderBooths = () => {
    return photoboothTypes.map((booth) => (
      <Fragment key={booth.id}>
        <Grid item xs={12} md={4} key={booth.id}>
          <Button
            variant="contained"
            style={{ backgroundColor: "red", color: "white" }}
            onClick={() => handleDeleteBooth(booth.id)}
          >
            Delete
          </Button>
          {booth.id === currentEditForm ? (
            <BoothForm
              isEditing
              boothData={booth}
              //   closeForm={() => setCurrentEditForm(null)}
              //   handleCreateTemplate={handleCreateTemplate}
              //   handleUpdateTemplate={handleUpdateTemplate}
            />
          ) : (
            <BoothCard
              boothData={booth}
              adminView
              //   handleDeleteTemplate={handleDeleteTemplate}
              //   handleEditClick={handleEditClick}
            />
          )}
        </Grid>
      </Fragment>
    ));
  };

  return (
    <Fragment>
      <Grid container>
        <Typography variant="h5" style={{ marginBottom: 15 }}>
          Edit Photobooth Section
        </Typography>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Container maxWidth="md">
                <TextField
                  value={title}
                  label="Title"
                  fullWidth
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Container>
            </CardContent>
            <CardContent>
              <Container maxWidth="md">
                <TextField
                  value={body}
                  label="Body"
                  multiline
                  fullWidth
                  onChange={(e) => setBody(e.target.value)}
                />
              </Container>
            </CardContent>
            <CardActions>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handlePhotoboothPageUpdate}
              >
                Save
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>

      {showNewBoothForm && (
        <VerticalSpacer top={25} bottom={25}>
          <BoothForm
            closeForm={() => setShowNewBoothForm(false)}
            // handleCreateBooth={handleCreateBooth}
          />
        </VerticalSpacer>
      )}

      <VerticalSpacer top={25} bottom={25} />

      {!showNewBoothForm && (
        <span style={{ float: "right" }}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={() => setShowNewBoothForm(true)}
          >
            New Booth
          </Button>
        </span>
      )}

      <Typography variant="h5" style={{ marginBottom: 15 }}>
        Current Booths
      </Typography>

      <Grid container spacing={1}>
        {renderBooths()}
      </Grid>
    </Fragment>
  );
};

export default EditPhotoboothPage;
