import { Fragment, useState, useRef, useEffect, useContext } from "react";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
  Checkbox,
  InputLabel,
  Box,
  Chip,
  CardMedia,
  CardActionArea,
  IconButton,
} from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import UploadBoothPhotos from "./UploadBoothPhotos";
import ImageGrid from "./ImageGrid";
import { createBoothTypes } from "../api";

import { SiteContext, PhotoboothPageContext } from "../Context/SiteContext";

const BoothForm = ({
  isEditing,
  boothData,
  handleUpdateTemplate,
  closeForm,
}) => {
  const fileInputRef = useRef();

  const [file, setFile] = useState(null);
  //   const [previewURL, setPreviewURL] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [photoboothPage, photoboothTypes] = useContext(PhotoboothPageContext);
  const [loadStateData] = useContext(SiteContext);

  //   useEffect(() => {
  //     if (templateData) {
  //       // setImage(templateData.image_url);
  //       setImageName(templateData.name);
  //       setImageSize(templateData.sizing);
  //       setFrontPage(templateData.front_page);
  //     }
  //   }, []);

  const validate = () => {
    let isFormValid = [];

    // if (!image) {
    //   isFormValid.push("image");
    // }
    // if (!imageSize) {
    //   isFormValid.push("imageSize");
    // }

    // return isFormValid.length;
  };

  //   const handleCreateBooth = (boothData) => {
  //     createBoothTypes(boothData)
  //       .then((payload) => loadStateData("CREATE_PHOTOBOOTH_TYPE", payload))
  //       //   .then(() => setShowNewBoothForm(false))
  //       .then(() => loadStateData("SUCCESS", "Created new booth!"))
  //       .catch((err) => loadStateData("ERROR", "Error creating new booth"));
  //   };

  const handleCreateBooth = (formData) =>
    createBoothTypes(formData)
      .then((payload) => loadStateData("CREATE_PHOTOBOOTH_TYPE", payload))
      //   .then(() => setShowNewBoothForm(false))
      .then(() => loadStateData("SUCCESS", "Created new booth!"))
      .catch((err) => loadStateData("ERROR", "Error creating new booth"));

  const handleSubmit = () => {
    console.log("HANLDE SUBUMT");
    let formData = new FormData();

    const boothForm = {
      name,
      description,
    };

    if (file) {
      boothForm["image"] = file;
    }
    console.log("SUBMIT", boothForm);

    Object.keys(boothForm).forEach((key) =>
      formData.append(`photobooth_type[${key}]`, boothForm[key])
    );

    // for (var value of formData.values()) {
    //   console.log(value);
    // }

    return formData;
  };

  const renderEditForm = () => <div>EDIT CATD</div>;

  const renderNewForm = () => (
    <Fragment>
      {/* <span style={{ float: "right" }}> */}
      <Button variant="outlined" color="primary" onClick={closeForm}>
        Cancel
      </Button>
      {/* </span> */}
      <Card>
        <CardHeader title="New Booth" />
        <CardContent>
          <Grid container spacing={5} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Name of Booth"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                fullWidth
                label="Description of booth"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={5} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <UploadBoothPhotos setFile={setFile} />
            </Grid>
            <Grid item xs={12} sm={6}>
              {file && (
                <Card>
                  <CardMedia
                    image={URL.createObjectURL(file)}
                    style={{ height: 250 }}
                  />
                </Card>
              )}
            </Grid>
          </Grid>
        </CardContent>

        <CardActions>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            disabled={!!validate()}
            onClick={() => handleCreateBooth(handleSubmit())}
          >
            Save
          </Button>
        </CardActions>
      </Card>
    </Fragment>
  );

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {isEditing ? renderEditForm() : renderNewForm()}
        </Grid>
      </Grid>
    </Fragment>
  );
};
export default BoothForm;
