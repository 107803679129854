// import './App.css';
import { useEffect, useContext, Fragment, forwardRef, useRef } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { getBoothTypes } from "../api";

import {
  Typography,
  Grid,
  Zoom,
  Fade,
  Container,
  CardContent,
  Card,
} from "@material-ui/core";

import Paragraph from "../Components/Paragraph";
import PackageCard from "../Components/PackageCards";
import { PhotoboothPageContext, SiteContext } from "../Context/SiteContext";
import VerticalSpacer from "../Components/VerticalSpacer";

import { useInView } from "react-intersection-observer";
import BoothCard from "../Components/BoothCard";

const useStyles = makeStyles((theme) => ({
  heading: {
    ...theme.heading,
  },
}));

const booths = [
  {
    name: "Mirror",
    description: "this is a mirror booth",
    images: ["img1", "img2", "img3"],
  },
  {
    name: "Full length",
    description: "this is a full length mirror booth",
    images: ["img1", "img2", "img3"],
  },
  {
    name: "360 Booth",
    description: "this is a 360 booth",
    images: ["img1", "img2", "img3"],
  },
];

function BoothsPage() {
  const classes = useStyles();
  const history = useHistory();
  const [photoboothPage, photoboothTypes] = useContext(PhotoboothPageContext);
  const [loadStateData] = useContext(SiteContext);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    triggerOnce: true,
    threshold: 1,
  });

  useEffect(() => {
    if (!photoboothTypes.length && inView) {
      getBoothTypes()
        .then((data) => loadStateData("PHOTOBOOTH_TYPES", data))
        .catch((error) => console.log("ERROR"));
    }
  }, [inView]);

  //   const handlePackageSelect = (packageData) => {
  //     console.log("PACKAGE DATA", packageData);
  //     history.push({
  //       pathname: "/request-booking",
  //       state: {
  //         selectedPackage: packageData,
  //       },
  //     });
  //   };

  if (!photoboothPage) {
    return null;
  }

  const renderCards = () => {
    return photoboothTypes.map((boothType, index) => (
      <Zoom key={index} in={inView} timeout={(index + 1) * 500}>
        <Grid item xs={12} md={4}>
          <BoothCard boothData={boothType} />
        </Grid>
      </Zoom>
    ));
  };

  return (
    <Container maxWidth="md">
      <Fade ref={ref} in timeout={1000}>
        <div>
          <Typography className={classes.heading} align="center">
            {photoboothPage.title}
          </Typography>

          <Paragraph>{photoboothPage.body}</Paragraph>
        </div>
      </Fade>
      <VerticalSpacer top={50} />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={2}
      >
        {renderCards()}
      </Grid>
    </Container>
  );
}

export default BoothsPage;
